import { CommonData } from "@/javascripts/types/models"

let commonData: CommonData | null = null

// Retrieve common data from the server side
export function getCommonData(): CommonData {
  if (commonData) {
    return commonData
  }

  const commonDataElement = document.getElementById("common-data")
  if (!(commonDataElement instanceof HTMLScriptElement)) {
    throw Error("failed to get the 'common-data'")
  }

  commonData = JSON.parse(commonDataElement.text) as CommonData

  return commonData
}

export function getInjectedData<T>(tag = "data_to_inject"): T {
  const dataToInject = document.getElementById(tag)
  if (!(dataToInject instanceof HTMLScriptElement)) {
    throw new Error("Script element not found")
  }

  // TODO: Validate it by JSON schema or something
  const injectedData = JSON.parse(dataToInject.text) as T

  return injectedData
}
